html {
	box-sizing: border-box;
  }
  *, *:before, *:after {
	box-sizing: inherit;
  }

@keyframes sea {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -1200px 0;
	}
}

@keyframes sky {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -800px 0;
	}
}

@keyframes bounce {
	0% {
		top: -3px;
	}
	100% {
		top: -5px;
	}
}

@keyframes border {
	0% {
		mask-border-width: 0 0 70px 0;
	}
	100% {
		mask-border-width: 0 0 50px 0;
	}
}

@keyframes seahorse {
	0% {
		top: 0;
		left: 0;
	}
	25% {
		left: 10px;
		top: 25%;
	}
	50% {
		left: 20px;
		top: 50%;
	}
	75% {
		left: 10px;
		top: 25%;
	}
	100% {
		left: 0;
		top: 0;
	}
}

@keyframes dive {
	0% {
		height: 100vh;
	}
	75% {
		height: 125vh;
	}
	100% {
		height: 100vh;
	}
}

@keyframes dive-body {
	0% {
		padding-top: 5vh;
	}
	75% {
		padding-top: 0;
	}
	100% {
		padding-top: 5vh;
	}
}

body {
	background: url(../img/sea-repeat-xy.jpg) repeat;
	background-color: #379AB2;
	color:#212121;
	padding: 0;
	font-family: 'Bree Serif', serif;
	animation: sea 60s linear infinite;
	background-position: 0 0;
	font-size: 18px; 
	line-height: 1.4;
	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

a:link, a:visited {
	color: #000000;
	border-bottom: #f0a202 solid;
	text-decoration: none;
}

a:hover, a:active, a:focus {
	color: #000000;
	border-bottom-color: #ffffff;
	outline-offset: 2px;
}

.skill {
	color: #000000;
	font-weight: bold;
}

h2 {
	font-size: 1.8em;
	color: #000000;
	margin-bottom: .25em;
}

.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

header {
	background: url(../img/sky-repeat-xy.jpg) repeat;
	animation: sky 60s linear infinite reverse;
	mask-border: url(../img/wave.svg) 44 repeat;
	mask-border-width: 0 0 70px 0;
	overflow: hidden;
	text-align: center;
	min-height: 10vh;
	padding-bottom: 100px;
	color: #000;
	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
	h1 {
		font-size: 3.5rem;
		margin-bottom: 0;
		padding-left: 15px;
		padding-right: 15px;
	}
	p {
		font-size: 1.5rem;
		padding-left: 15px;
		padding-right: 15px;
	}
}

nav {
	text-align: left;
}

.hero {
	height: 100vh;
}

footer {
	background: url(../img/sand.png) repeat-x;
	background-position: left bottom;
	min-height: 190px;
	//height: 25vh;
	padding: 0 15px 25px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	small {
		display: inline-block;
		padding: 2px;
		background-color: rgba(55,154,178,.7)
	}
	@media screen and (min-width: 900px) {
		padding-left: 100px;
		padding-right: 100px;
	}
}

#main {
	min-height: 80vh;
	//max-width: 1100px;
	margin: auto;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 5vh;
	max-width: 960px;
}

ul {
	list-style-type: none;
	padding-left: 0;
	margin-left: 0;
}

.project-grid {
	@media screen and (min-width: 768px) {
		display: grid;
		grid-gap: 30px;
		grid-template-columns: 1fr 1fr;
	}
	img {
		display: block;
		width: 100px;
		border-radius: 50%;
		margin: 15px;
	}
}

.project-listing {
	img {
		max-width: 100%;
	}
	li {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
		@media screen and (min-width: 900px) {
			flex-direction: row;
		}
	}
	h3 {
		margin-top: 0;
	}
}

.project-listing__image {
	flex-shrink: 0;
	margin-bottom: 15px;
	a {
		border-bottom: none;
	}
	@media screen and (min-width: 900px) {
		flex-basis: 330px;
		padding: 0 15px;
	}
}

.flex {
	display: flex;
}

.intro {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	padding-left: 15px;
	padding-right: 15px;
}

.content {
	max-width: 35em;
}

.featured {
	font-size: 1.2em;
}

.social {
	margin-right: 15px;
	svg {
		width: 45px;
		height: 45px;
	}
}

.content-graphic {
	display: none;
	@media screen and (min-width: 900px) {
		padding: 15px 15px 0;
		display: flex;
		flex-grow: 1;
		align-items: flex-start;
		justify-content: center;
		svg {
			width: 100px;
			height: 100px;
			margin-right: 15px;
		}
	}
}

button, .button {
	display: inline-block;
	padding: 10px 15px;
	margin: 5px;
	border-radius: 5px;
	border: none;
	background-color: #1f5463;
	color: #fff;
	cursor: pointer;
	&:link, &:visited {
		color: #fff;
		text-decoration: none;
		border-bottom: none;
	}
}

.intro-button:after {
	content: "\2193";
	position: relative;
	animation: bounce .5s ease-in-out infinite;
	padding-left: 10px;
	
}

button:hover, .button:hover {
	background-color: #007AB3;
}

.dive {
	animation: dive ease-in-out 1s forwards, sky 60s linear infinite reverse;
}

.dive + .main {
	animation: dive-body ease-in-out 1s forwards;
}