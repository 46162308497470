.article {
    max-width: 960px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    & + & {
        padding-top: 50px;
        margin-top: 50px;
        border-top: 5px solid rgba(255,255,255,.5);
    }
}

.article-entry {
    ul {
        list-style-type: disc;
        padding-left: 2rem;
        li {
            margin-bottom: .5rem;
        }
    }
}

.article-meta {
    display: flex;
    align-items: center;
}

ul.article-tag-list {
    display: flex;
    list-style-type: none;
    .article-tag-list-item {
        margin-left: 10px;
    }
}

.blog-icon {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 5px;
}

.blog-nav {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.blog-nav-link {
    margin-right: 15px;
    svg {
        width: 35px;
        height: 35px;
        vertical-align: middle;
        margin: 5px;
        color: #000;
    }
    .blog-nav-home & {
        &:link, &:visited {
            color: #000;
        }
    }
    &:link, &:visited {
        border: none;
    }
    &:hover .blog-nav-text {
        border-bottom-color: #fff;
    }
}

.blog-nav-text {
    border-bottom: 3px solid #f0a202;
}

.widget-wrap {
    padding: 10px 20px;
    border: 3px solid rgba(255,255,255,.5);
    margin-bottom: 15px;
}

.article-tag-list-link {
    display: inline-block;
    background-color: #f0a202;
    color: #000;
    border-radius: 15px;
    font-size: .8rem;
    padding: 2px 5px;
    min-width: 50px;
    text-align: center;
    &:link, &:visited {
        color: #000;
    }
    &:hover {
        background-color: #fff;
        border-bottom-color: #fff;
    }
}

.post-img {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 15px auto;
}

.recent-list-item {
    margin-bottom: .25em;
}